import { motion } from "framer-motion";

const Navbar = () => {
  return (
    <nav className="flex justify-center items-center mx-auto !px-8 pt-0 md:pb-6 max-w-[1280px]">
      <motion.a
        href="/"
        transition={{ duration: 0.2 }}
        initial={{
          scale: 1,
        }}
        whileHover={{ scale: 1.2 }}
      >
        <img
          src="/images/BrandLogo.webp"
          alt="Freelance Academy"
          width={136}
          height={48}
        />
      </motion.a>
    </nav>
  );
};

export default Navbar;
